<template lang="pug">
include ../../../configs/template
div
  v-card-title(v-if="!$route.params.documentID")
    v-tabs(v-model="activeTab" color="primary" grow)
      v-tab(v-for="tab in tabs.filter(el => !el.hasOwnProperty('checkAccess') || el.checkAccess())" :key="tab.id" @click="tab.action()") {{$t(tab.label)}}
  v-card-text.pt-4
    form.row
      // ----------------------ADDED NEW DOXUMENT ABOUT STATEMENT--------//
      div(v-if="!activeTab && !$route.params.documentID").row
        div.col-12
          +select-validation('body.statement', 'successQualificationStatement', 'approvedStatements', 'nameLang', '["required"]')(return-object)
      // ----------------------ADDED EXISTS DOCUMENTS-----------------//
      div(v-else).row
        div.col-12
          +select-validation('body.type_document', 'qualificationDocumentTypes', 'typeDoc', 'nameLang', '["required"]')
            template(#item="{ item }")
              div(:class="{ 'red-option': item.is_disable }") {{ item[nameLang] }}
        div.col-12(v-if="!requiredField.proof.includes(body.type_document)")
          //- div(v-if="body?.number").col-12.col-sm-12
            +field('body.number', 'number')
          +field-validation('body.number', 'number', '["required"]')(v-if="body?.number")
          +field-validation('body.other_number', 'number', '["required", "maxLength", "minLength"]')(v-else)
        div.col-sm-12.col-md-6(v-if="!requiredField.proof.includes(body.type_document)")
          +select-validation('body.country', 'countriesList', 'country', 'langCountry', '["required"]')(
            @input="body.port = null")
        div(v-if="body.country === COUNTRY_CONSTANTS.UKRAINE || requiredField.proof.includes(body.type_document)").col-sm-12.col-md-6
          +select-validation('body.port', 'portsList', 'port', 'nameLang', '["required"]')
        div(v-else).col-sm-12.col-md-6
          +field-validation('body.other_port', 'port', '["required"]')
        div.col-12(v-if="requiredField.proof.includes(body.type_document)")
          +select-validation('body.diploma', 'listDiplomas', 'diploma', 'nameLang', '["required"]')(
            @input="mappingFunctionByPosition" :item-text="customDiplomaLabel")
        div(v-if="!requiredField.proof.includes(body.type_document)").col-sm-12.col-md-6
          +select-validation('body.rank', 'filteredRanksList', 'rank', 'nameLang', '["required"]')
            template(#item="{ item }")
              div(:class="{ 'red-option': item.is_disable }") {{ item[nameLang] }}
        div(v-if="!requiredField.proof.includes(body.type_document)").col-sm-12.col-md-6
          +select-validation('body.list_positions', 'listPosition', 'position', 'nameLang', '["required"]')(multiple)
            template(#item="{ item }")
              div(:class="{ 'red-option': item.is_disable }") {{ item[nameLang] }}
        div(v-if="body.diploma").col-12.text-left
          b.col-12.px-0 {{ $t('limitation') }}:
          div(v-for="(func, index) of body.function_limitation" :key="func.id").col-12.px-0 {{ qualificationLimitationFunctionByID(func.id_func).function[nameLang]  }}:
            +select('body.function_limitation[index].id_limit', 'listPositionLimitation', '', 'nameLang', '[]')(
              v-if="qualificationLimitationFunctionByID(func.id_func).function.name_ukr !== 'немає'"
              multiple item-value="id")
        div.col-sm-12.col-md-6
          +date-picker-validation('body.date_start', 'dateIssue', 'dateStartObject', '["required", "minValue", "maxValue"]')(
            :max="new Date().toISOString()" min="1900-01-01")
        div(v-if="requiredField.date_end.includes(body.type_document)").col-sm-12.col-md-6
          +date-picker-validation('body.date_end', 'dateTermination', 'dateEndObject', '["required", "minValue", "maxValue"]')(
            :min="body.date_start" max="2200-12-31")
        div.col-12
          +field-validation('body.strict_blank', 'strictBlank', '["required", "maxLength", "minLength"]')(v-mask="'NNN######'")
        div.col-12.px-0(v-if="checkAccess('qualification', 'add_file')")
          FileDropZone(ref="mediaContent").col-12

      div.col-12
        v-btn(color="success" @click="saveQualificationDocuments" :loading="isLoading") {{ $t('btn.save') }}
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { STATUSES_DOCUMENT_CONSTANTS, COUNTRY_CONSTANTS, SUCCESS_CODE } from '@/configs/constants'
import { sailorQualificationAdding } from '@/mixins/validationRules'
import { mapActions, mapGetters, mapState } from 'vuex'
import { clearBody } from '@/mixins/main'

const initBody = () => ({
  new_document: true,
  statement: null,
  status_document: STATUSES_DOCUMENT_CONSTANTS.SAILOR.QUALIFICATION.VALIDED,
  type_document: null,
  country: null,
  port: null,
  other_port: null,
  other_number: null,
  number: null,
  date_start: null,
  date_end: null,
  function_limitation: [],
  rank: null,
  list_positions: null,
  diploma: null,
  strict_blank: null
})

export default {
  name: 'FormCreateEditQualificationDocument',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      COUNTRY_CONSTANTS,
      activeTab: 0,
      tabs: [
        { id: 1, label: 'addNewQualificationDoc', checkAccess: () => checkAccess('qualification', 'createNewQualification'), action: () => (this.body.new_document = true) },
        { id: 2, label: 'addExistQualificationDoc', action: () => (this.body.new_document = false) }
      ],
      body: initBody(),
      isLoading: false,
      functionPosition: [],
      requiredField: {
        proof: [16],
        date_end: [16, 21, 57, 85, 86, 88, 89]
      }
    }
  },
  computed: {
    ...mapGetters(['diplomasForQualificationDocs', 'availableDiplomasByRank', 'functionByPosition', 'positionsByRankID',
      'statusById', 'rankByID', 'qualificationLimitationFunctionByID']),
    ...mapState({
      id: state => state.sailor.sailorId,
      langCountry: state => state.main.lang === 'en' ? 'value_eng' : 'value',
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      ranksList: state => state.directory.ranks,
      listDiplomas: state => state.sailor.diplomas,
      qualificationDocumentTypes: state => state.directory.typeDocQualification,
      countriesList: state => state.directory.country,
      portsList: state => state.directory.ports,
      successQualificationStatement: state => state.sailor.successQualificationStatement,
      listPositionLimitation: state => state.directory.positionLimitation,
      positionFunction: state => state.directory.positionFunction
    }),
    filteredRanksList () { // Allow only ranks that are available for the selected document type
      return this.ranksList.filter(item => item.type_document === this.body.type_document)
    },
    dateStartObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    },
    dateEndObject () {
      return this.body.date_end ? new Date(this.body.date_end) : null
    },
    listPosition () {
      return this.body.rank ? this.positionsByRankID(this.body.rank) : []
    },
    checkTypeDocumentForCreateDocWithStatement () {
      if (this.body.statement) {
        return false
      } else return this.body.type_document === 16
    }
  },
  watch: {
    'body.type_document' (val) { // Clear rank and positions if type document was changed
      if (val !== this.body.type_document) {
        this.body.rank = null
        this.body.list_positions = []
      }
    }
  },
  validations () { return sailorQualificationAdding(this) },
  mounted () {
    if (this.$route.params?.documentID) {
      Object.keys(this.body).forEach(key => (this.body[key] = this.sailorDocument[key]))
      this.body.strict_blank = this.sailorDocument.strict_blank || `${this.sailorDocument.letter_strict_blank}${this.sailorDocument.number_strict_blank?.toString().padStart(6, '0')}`
      // Clear rank and position below if current rank is not suitable for document type
      if (this.body.type_document === 1) return // this exclude for type document diploma radiooperator
      if (this.body.type_document !== 16 && this.rankByID(this.body.rank).type_document !== this.body.type_document) {
        this.body.rank = null
        this.body.list_positions = []
      }
      if (this.body.type_document === 16 && !this.body.function_limitation.length) this.mappingFunctionByPosition(this.body.diploma)
    }
    this.getQualificationDiplomas()
  },
  methods: {
    ...mapActions(['setQualificationDocuments', 'updateQualificationDocuments', 'getQualificationDiplomas',
      'setQualificationDiplomaProof', 'updateQualificationDiplomaProof']),
    async saveQualificationDocuments () {
      let data = {
        ...this.$route.params,
        body: { ...this.body }
      }
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      if (!this.activeTab && !this.$route.params.documentID) {
        data.body.type_document = data.body.statement.type_document
        data.body.statement = data.body.statement.id
        data.body = { ...clearBody({ ...data.body }, ['id_limit']) }
      } else {
        data = {
          ...data,
          body: { ...clearBody({ ...data.body }, ['id_limit']) },
          media: {
            files: [...this.$refs.mediaContent.filesArray],
            file_type: this.body.type_document === 16 ? 'proof_of_work_diploma' : 'qualification_document'
          }
        }
      }
      const response = !this.$route.params.documentID
        ? await this[this.checkTypeDocumentForCreateDocWithStatement ? 'setQualificationDiplomaProof' : 'setQualificationDocuments'](data)
        : await this[this.body.type_document === 16 ? 'updateQualificationDiplomaProof' : 'updateQualificationDocuments'](data)
      if (SUCCESS_CODE.includes(response.code)) {
        if (this.$route.params.documentID) this.$notification.success('editedQualificationDoc')
        else this.$notification.success('addedQualificationDoc')
        this.$parent.isViewAddSlot = false
        this.$v.$reset()
        this.body = initBody()
      }
      this.isLoading = false
    },
    mappingFunctionByPosition (id) {
      let item = (this.listDiplomas.find(el => el.id === id))?.list_positions
      if (item && !Array.isArray(item)) {
        this.body.function_limitation = this.positionFunction.filter(el => el.id === item.id).map(el => ({ id_func: el.id, id_limit: [] }))
      } else if (item && Array.isArray(item)) {
        this.body.function_limitation = this.positionFunction.filter(el => {
          return el.position === item[0]
        }).map(el => ({ id_func: el.id, id_limit: [] }))
      } else this.body.function_limitation = []
    },
    customDiplomaLabel (label) {
      return `${label.number} ${this.rankByID(label.rank)[this.nameLang]} (${this.statusById(label.status_document)[this.nameLang]})`
    }
  }
}
</script>
